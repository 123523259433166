import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]


export const protectedRoutes = [

    {
        key: 'Orders',
        path: `${APP_PREFIX_PATH}/orders`,
        component: React.lazy(() => import('views/app-views/orders')),
    },
    {
        key: 'Roles',
        path: `${APP_PREFIX_PATH}/roles`,
        component: React.lazy(() => import('views/app-views/roles')),
    },
    {
        key: 'Clientes',
        path: `${APP_PREFIX_PATH}/clients`,
        component: React.lazy(() => import('views/app-views/companies')),
    },
    {
        key: 'Análisis',
        path: `${APP_PREFIX_PATH}/tests`,
        component: React.lazy(() => import('views/app-views/tests')),
    },
    {
        key: 'Usuarios',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users')),
    },
    {
     
        path: `${APP_PREFIX_PATH}/results/:id`,
        component: React.lazy(() => import('views/app-views/results')),
    },
    {
        key: 'Resultados',
        path: `${APP_PREFIX_PATH}/resultsAll`,
        component: React.lazy(() => import('views/app-views/resultsAll')),
    },
    {
        key: 'Reportes',
        path: `${APP_PREFIX_PATH}/reports`,
        component: React.lazy(() => import('views/app-views/reports')),
    }

]