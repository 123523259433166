import React, {useState,useEffect} from "react";

import '../orders/style.css'
import TableRoles from 'components/Roles/Tables/Index'
import {Card, Button} from 'antd';
import ApiService from "services/ApiService";
import ModalRol from 'components/Roles/Modals/ModalFormCreate';
import ModalUpdate from 'components/Roles/Modals/ModalFormUpdate';


const Role = () => {

  const [dataSource, setDataSource] = useState([]);
  const [isModalRol, setIsModalRol] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);

  const [dataRole, setDataRole] = useState(null);


  const showModalRol= () => {
    setIsModalRol(true);
  };


  const showModalUpdate= (record) => {
    setIsModalUpdate(true);
    setDataRole(record)
  };


  const handleOk = () => {
   
    setIsModalRol(false);
    setIsModalUpdate(false);
    
  };

  const handleCancel = () => {
    setIsModalRol(false);
    setIsModalUpdate(false);
  };

  
  useEffect(() => {

    getRoles();
   
  }, [])



  const getRoles = () => {
    ApiService.getRoles()
      .then(response => {
      
        setDataSource(response.data.data);
        
      })
      .catch(e => {
        console.log(e);
      });
  };
 
  return (
    <>


     
        <Card>
          <h1>Roles</h1>
         
          <Button type="primary" onClick={showModalRol} className="right-align-button">Nuevo Rol</Button>
                                    
            <TableRoles getRoles={getRoles} dataSource={dataSource} showModalUpdate={showModalUpdate}/>
        </Card>
        <ModalRol  isModalOpen={isModalRol} handleOk={handleOk} handleCancel={handleCancel} getRoles={getRoles}/>
        <ModalUpdate isModalOpen={isModalUpdate} handleOk={handleOk} handleCancel={handleCancel} dataRole={dataRole} getRoles={getRoles}/>
    </>
  );
};

export default Role;
