import React, { useEffect,useState  }from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { getAuthenticatedEntry } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import Login from 'views/auth-views/authentication/login';
import Roles from 'views/app-views/roles';
import { useDispatch } from 'react-redux';
import { authenticated } from '../store/slices/authSlice'; 

const Routes = () => {

	const userRole = JSON.parse(localStorage.getItem('user')); 
	
	const [authenticatedEntry, setAuthenticatedEntry] = useState();
	
	useEffect(() => {

		if(userRole != null){
			
			
			setAuthenticatedEntry(getAuthenticatedEntry(userRole.role_id));
		}
		
	  }, [userRole])
	
	
	  return (
		<RouterRoutes>


          {/*
           <Route index element={<Login/>}/>
		   <Route path="/login" element={<Login/>}/>
	       <Route path="/roles" element={<Roles/>}/>*/}




			
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={authenticatedEntry} />} />
				{protectedRoutes.map((route, index) => {
					return (
						<Route 
							key={route.key + index} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/>
					)
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route 
							key={route.path} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/ >
					)
				})}
			</Route>


		</RouterRoutes>
	)
}

export default Routes