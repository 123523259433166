import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'
import { useEffect } from 'react';

export const APP_NAME = 'Lerim';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'


export function getAuthenticatedEntry(id) {
	

		if (id === 3   ) {

			
			return `${APP_PREFIX_PATH}/resultsAll`;
		  } else {
			
			return `${APP_PREFIX_PATH}/orders`; 
			
		  }
	
  }
  



const userRole = JSON.parse(localStorage.getItem('user')); 



export const AUTHENTICATED_ENTRY = getAuthenticatedEntry(userRole?(userRole.role_id):(null));


export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
