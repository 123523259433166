import React from 'react'
import {Form,Input,Button,Select,TextArea} from 'antd';

import ApiService from 'services/ApiService';
import Swal from 'sweetalert2';



export default function FormBranch(props) {


  const{ handleCancel, getRoles } = props;

  
  console.log("getRoles",getRoles)




  const onFinish = (values)=>{
   
   


      Swal.fire({
        title: '¿Estas seguro de crear un nuevo Rol?',
        text: "Sera visible en la parte de registro de Roles",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1cd49a',
        cancelButtonColor: '#e1413b',
        confirmButtonText: 'Si, crearla'
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("name",values.name);
          formData.append("description",values.description);
       
          ApiService.postRoles(formData)
          .then(response => {
            console.log(response);
            getRoles(); 
            handleCancel();
            Swal.fire(
              '¡Listo!',
              response.data.message,
              'success'
            )
           
  
          })
          .catch(e => {
            console.log(e);
          });
        }
      })
  }


  return (
    <>
        <Form
        labelCol={{span: 24,}}
        wrapperCol={{span: 24,}}
        layout="vertical"
        onFinish={onFinish}
        size={"small"}
        >



       
          
          <Form.Item name={'name'} label="Nombre del Rol" rules={[{ required: true }]}>
              <Input />
          </Form.Item>

          <Form.Item name={'description'} label="Descripción" rules={[{ required: true }]}>
              <Input.TextArea />
          </Form.Item>

      

          <Form.Item >
              <Button type="primary" htmlType="submit">
                  Guardar
              </Button>
          </Form.Item>
        </Form>

        

    </>

  )
}
