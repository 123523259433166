import React from 'react'
import {Form,Input,Button,InputNumber} from 'antd';
import Swal from 'sweetalert2';
import ApiService from 'services/ApiService';


export default function FormUpdate(props) {


   
    const { getRoles, handleCancel, dataRole} = props;
  
  

    const onFinish = (values)=>{
       
      Swal.fire({
        title: '¿Estás seguro de actualizar  el Rol?',
        text: "Será visible en la parte de registro de Roles",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1cd49a',
        cancelButtonColor: '#e1413b',
        confirmButtonText: 'Sí, actualizarlo'
      }).then((result) => {
        if (result.isConfirmed) {

      
          ApiService.updateRol(values)
          .then(response => {
            console.log(response);
            getRoles();
            handleCancel();
          })
          .catch(e => {
            console.log(e);
          });
        }
      })
 
           

    }
  return (

        <Form
        labelCol={{span: 24,}}
        wrapperCol={{span: 24,}}
        layout="vertical"
        onFinish={onFinish}
        size={"small"}
        initialValues={{ remember: true }}
    >
        {
                                dataRole ? (
                                    <Form.Item 
                                        style={{display:"none"}}
                                        name="id"
                                        rules={[{ required: true, message: '' }]}
                                        initialValue={dataRole.id}
                                    >
                                        <Input defaultValue={dataRole.id} />
                                    </Form.Item>
                                ): null
        }

        {
            dataRole !== null || dataRole ? ( <Form.Item name={'name'} label="Nombre" rules={[{ required: true }]} initialValue={dataRole.name}><Input /></Form.Item>) : null
        }
        {
            dataRole !== null || dataRole ? ( <Form.Item name={'description'} label="Descripción" rules={[{ required: true }]} initialValue={dataRole.description}><Input.TextArea /></Form.Item>) : null
        }
      
        

        <Form.Item >
            <Button type="primary" htmlType="submit">
                Actualizar
            </Button>
        </Form.Item>
    </Form>
  )
}
