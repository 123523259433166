import React,  {useState, useEffect} from 'react'
import {Table, Space, Tooltip,Button,Switch, Checkbox} from 'antd';
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import ApiService from 'services/ApiService';
import Swal from 'sweetalert2';
import './TableRoles.css';
export default function TableRoles( props ) {

      const { dataSource,showModalUpdate, getRoles } = props;




      const onChange = (checked, value) => {
        console.log(`Enviar notificación: ${checked}  ${value}`);

        if(checked===true){
            console.log("si enviar")
            var formData = new FormData();
            formData.append("id",value);
            ApiService.yesNotification(formData)
            .then(response => {

              getRoles();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "¡Listo!",
                showConfirmButton: false,
                timer: 1200
              });
              
            })
            .catch(e => {
              console.log(e);
            });

        }else{
           console.log("no enviar")
           var formData = new FormData();
            formData.append("id",value);
            ApiService.noNotification(formData)
            .then(response => {

              getRoles();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "¡Listo!",
                showConfirmButton: false,
                timer: 1200
              });
              
            })
            .catch(e => {
              console.log(e);
            });
        }




      };

      const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {

   
        if(dataSource.length === 0){
        
          setIsLoading(true);
  
        }else{
  
          setIsLoading(false);
  
        }
        
        
       
      }, [dataSource])


      const setRoleEnabled =(value)=>{
       

            var formData = new FormData();
            formData.append("id",value);
            ApiService.setRoleEnabled(formData)
            .then(response => {

              getRoles();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "¡Listo!",
                showConfirmButton: false,
                timer: 1200
              });
              
            })
            .catch(e => {
              console.log(e);
            });
         
       
 
       
      }
      const setRoleDisabled=(value)=>{
        

      
            var formData = new FormData();
            formData.append("id",value);

            ApiService.setRoleDisabled(formData)
            .then(response => {
              getRoles();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "¡Listo!",
                showConfirmButton: false,
                timer: 1200
              });
              
            })
            .catch(e => {
              console.log(e);
            });
         

 
       
      }
      
      
      const columns = [
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Descripción',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: "Enviar notificación de nueva orden",
          dataIndex: "Actions",
          key: "actions",
      
          render: (text, record) => (
            
            <>
              <Space size={20}>
                <Tooltip title={record.notification===0 ? 'Enviar notificación' : "No Enviar notificación" }>
                  <Checkbox  defaultChecked={record.notification === 0 ? false : true} 
                             onChange={(e) => onChange(e.target.checked, record.id)}>

                  </Checkbox>
                </Tooltip>
              </Space>
            </>
          ),
        },
        {
          title: "Acciones",
          dataIndex: "Actions",
          key: "actions",
      
          render: (text, record) => (
            
            <>
              <Space size={20}>
                <Tooltip title="Editar">
                  <Button 
                  type="primary" 
                  shape="circle" onClick={ ()=>showModalUpdate(record) } 
                  icon={<EditOutlined style={{ color: '#389e0d' }} />}
                  style={{ backgroundColor: '#CCF1B0',
                           borderColor: '#389e0d',
                            }} />
                </Tooltip>

              </Space>
              


            </>
          ),
        },
        {
          title: "Estatus",
          dataIndex: "Status",
          key: "status",
          render: (text, record) => (
            <>
              <Space size={20}>
                <Tooltip title={record.status==='Enabled' ? 'Desactivar' : "Activar" }>
                  {record.status==='Enabled' ? <Switch onClick={()=>setRoleDisabled(record.id) } defaultChecked /> : <Switch onClick={()=>setRoleEnabled(record.id)} />}
                </Tooltip>
              </Space>
            </>
          ),

        },
      ];

    return (
        <>
             <Table  loading={isLoading} dataSource={dataSource} columns={columns} />
        </>
    );
}