import React, { useState } from 'react'
import {Modal,Button} from 'antd';
import FormRol from 'components/Roles/Form/FormsCreate'

export default function ModalBranch(props) {

   const {isModalOpen, handleOk, handleCancel, dataSource, getRoles }= props;

    

 


  return (
    <div>
       

        <Modal title="Rol"   open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} destroyOnClose={true}>
           <FormRol getRoles={getRoles} handleCancel={handleCancel}/>
        </Modal>
    </div>

  )
}
