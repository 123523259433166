import React, { useState } from 'react'
import {Modal,Button} from 'antd';
import FormUpdate from 'components/Roles/Form/FormUpdate';

export default function ModalBranch(props) {

   const {isModalOpen, handleOk, handleCancel, getRoles, dataRole }= props;


  return (
    <div>
       

        <Modal title="Rol"   visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} destroyOnClose={true}>
          <FormUpdate dataRole={dataRole} getRoles={getRoles} handleCancel={handleCancel} />
        </Modal>
    </div>

  )
}
