import React, { lazy, Suspense, memo, useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes'
import { AuthProvider } from 'contexts/auth';
import { authenticated } from '../store/slices/authSlice'; 

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {

	const token = useSelector(state => state.auth.token);

	
    /*const token = localStorage.getItem('auth_token');
	
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
    const nuevoToken = localStorage.getItem('auth_token');

	useEffect(() => {
		
		if (nuevoToken) {
			console.log("nuevoToken ",nuevoToken)
		  dispatch(authenticated(nuevoToken));
		}
	  }, [dispatch, nuevoToken]);*/



	const blankLayout = useSelector(state => state.theme.blankLayout);

	const Layout = token && !blankLayout ? AppLayout : AuthLayout;

	const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme}

	return (
		<ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
			<Suspense fallback={<Loading cover="content"/>}>
				<Layout>
				  <AuthProvider>
					 <Routes />
				   </AuthProvider>
				</Layout>
			</Suspense>
		</ConfigProvider>
	)
}

export default memo(Layouts)