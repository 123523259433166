import axios from "axios";
import { AUTH_TOKEN } from "redux/constants/Auth";

const URL_BASE =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

const TOKEN_PAYLOAD_KEY = "authorization";
const ENTRY_ROUTE = "/auth/login";

class ApiService {
  _instance;

  constructor() {
    let path = "";
    if (typeof window !== "undefined") {
      path = window.location.pathname;
    }

    this._instance = axios.create({ baseURL: URL_BASE });
    this._instance.interceptors.request.use(
      function (config) {
        const jwtToken = localStorage.getItem(AUTH_TOKEN);

        if (jwtToken) {
          config.headers = {
            ...config.headers,
            [TOKEN_PAYLOAD_KEY]: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
            
          };
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this._instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401 && path !== ENTRY_ROUTE) {
          localStorage.clear();
          window.location.pathname = ENTRY_ROUTE;
        }
        return Promise.reject(error);
      }
    );
  }

  async signin(params) {
    return await this._instance.post("/login", params);
  }

  async signup(params) {
    return await this._instance.post("/register", params);
  }

  async getUser() {
    return await this._instance.post("/authUser");
  }

 


  async getRoles() {
    
    return await this._instance.get("/roles");
  }

  async postRoles(params) {
    return await this._instance.post("/roles",params);
  }

  async updateRol(params) {
    return await this._instance.put("/update-rol",params);
  }

  async setRoleDisabled(params) {
    return await this._instance.post("/rol-disabled",params);
  }  

  async setRoleEnabled(params) {
    return await this._instance.post("/rol-enabled",params);
  }  
  
  async getShowRolesStatus() {
    return await this._instance.get("/roles-status");
  }  
  





  async getShowCompaniesStatus() {
    
    return await this._instance.get("/companies-status");
  }

  async getCompanies(id) {
    return await this._instance.get(`/companies/${id}`);
  }

  async postCompanies(params) {
    return await this._instance.post("/companies",params);
  }

  async getCompaniesAll() {
    return await this._instance.get(`/companies`);
  }

  async setCompanyDisabled(params) {
    return await this._instance.post("/company-disabled",params);
  }  

  async setCompanyEnabled(params) {
    return await this._instance.post("/company-enabled",params);
  }  

  async updateCompany(params) {
    return await this._instance.put("/update-company",params);
  }







  async getTests() {
    
    return await this._instance.get("/tests");
  }

  async postTests(params) {
    return await this._instance.post("/tests",params);
  }

  async setTestDisabled(params) {
    return await this._instance.post("/test-disabled",params);
  }  

  async setTestEnabled(params) {
    return await this._instance.post("/test-enabled",params);
  }  

  async updateTest(params) {
    return await this._instance.put("/update-test",params);
  }

  async getShowTestsStatus() {
    
    return await this._instance.get("/tests-status");
  }



  async getOrdersbyID(Id) {
    
    return await this._instance.get(`/orders/${Id}`);
  }



  async getOrders(parameters) {
    
   
    return await this._instance.get(`/orders?start=${parameters.start}&end=${parameters.end}&start_date=${parameters.start_date}&end_date=${parameters.end_date}&folio=${parameters.folio}&company_name=${parameters.company_name}&test_id=${parameters.test_id}&delivery=${parameters.delivery}`);
  }

  async postOrders(params) {
    return await this._instance.post("/orders",params);
  }

  async getFolio() {
    
    return await this._instance.get("/folio");
  }

  async setOrderDisabled(params) {
    return await this._instance.post("/order-disabled",params);
  }  

  async setOrderEnabled(params) {
    return await this._instance.post("/order-enabled",params);
  }  


  async updateOrder(params) {
   
    return await this._instance.post( `/orders-update/${params.id}`,params);
  }

  
  async getOrdersbyRangeDates(params) {
    return await this._instance.get(`/orders-by-dates/?from=${params.from}&to=${params.to}&start=${params.start}&end=${params.end}`);
  }






  async getUsers() {
    
    return await this._instance.get("/users");
  }

  async postUsers(params) {
    return await this._instance.post("/users",params);
  }

  async updateUsers(params) {
   
    return await this._instance.put( `/update-user/${params.id}`,params);
  }

  async updateStatusUsers(params) {
   
    return await this._instance.post( `/users-status-change/`,params);
  }

  async getTypes() {
    
    return await this._instance.get("/types");
  }



  async updateresultsWater(params) {
    return await this._instance.put("/resultsWater-update",params);
  }

  async updateresultsFood(params) {
    return await this._instance.put("/resultsFood-update",params);
  }


  async updateresultsSurface(params) {
    return await this._instance.put("/resultsSurface-update",params);
  }


  async updateresultsSolution(params) {
    return await this._instance.put("/resultsSolution-update",params);
  }

  async updateresultsEnviroment(params) {
    return await this._instance.put("/resultsEnviroment-update",params);
  }




  async getSamplings(parameters) {
   
    return await this._instance.get( `/samplings/?start=${parameters.start}&end=${parameters.end}&type_id=${parameters.type_id}`);
  }


  async sendEmail(params) {
   
    return await this._instance.post( `/sendEmail`,params);
  }

  async sendEmailResults(params) {
   
    return await this._instance.post( `/sendEmailResults`,params);
  }

  async updateSamplings(params) {
   
    return await this._instance.put( `/samplings-update`,params);
  }


 

  async getTotalesOrders() {
    return await this._instance.get( `/totales-orders`);
  }

  async getTotalesSamplings() {
    return await this._instance.get( `/totales-samplings`);
  }


  async checkRoleExists() {
    return await this._instance.get( `/checkRoleExists`);
  }



  async noNotification(params) {
    return await this._instance.post("/rol-noNotification",params);
  }  

  async yesNotification(params) {
    return await this._instance.post("/rol-yesNotification",params);
  }  


  async downloadPDF3(data) {
 
    
    return await this._instance.get( `/downloadPDF3/${data}`);
  }


  async downloadPDF2(data) {
 
    return await this._instance.post( `/downloadPDF2`,data);
  }


  async validateOrder(params) {
    return await this._instance.post("/validateOrder",params);
  }
  
  
  async destroyOrder(data) {
 
    return await this._instance.delete( `/orders/${data}`);
  }


  async destroySampling(data) {
 
    return await this._instance.delete( `/samplings/${data}`);
  }


  async getCountTypes() {
 
    return await this._instance.get( `/getCountTypes`);
  }

  async totalordersbyrange(params) {
    return await this._instance.get(`/totalordersbyrange/?start_date=${params.from}&end_date=${params.to}`);
  }


  async setSamplingDisabled(params) {
    return await this._instance.post("/sampling-disabled",params);
  }  

  async setSamplingEnabled(params) {
    return await this._instance.post("/sampling-enabled",params);
  }  



  async changeStatusPayOrder(params){
    return await this._instance.put(`/orders-changeStatusPay/${params.id}`, params);
    
  }



}

export default new ApiService();
